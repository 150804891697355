.icon_check {
  @apply block relative pl-6;

  > svg {
    @apply block left-0 absolute;
    top: rem(6);
    width: rem(16);
    height: rem(16);
    // fill: #fff;
    @screen sm {
      top: rem(5);
    }
  }
}
