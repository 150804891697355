.modal {
  $this: &;
  display: none;
  &.is-open {
    display: block;
  }
  &[aria-hidden='false'] {
    #{$this}__overlay {
      animation: fadeIn .3s cubic-bezier(0, 0, .2, 1);
    }
    //#{$this}__container {
    //  animation: mmslideIn .3s cubic-bezier(0, 0, .2, 1);
    //}
  }
  &[aria-hidden='true'] {
    #{$this}__overlay {
      animation: fadeOut .3s cubic-bezier(0, 0  , .2, 1);
    }
    //#{$this}__container {
    //  animation: mmslideOut .3s cubic-bezier(0, 0, .2, 1);
    //}
  }
  &__overlay {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    background: rgba(0,0,0,.6);
  }
  [role="dialog"] {
    cursor: default;
  }
}
