.inquiry {
  .button {
    @apply py-4 text-lg;
    @screen sm {
      @apply py-8;
      font-size: rem(25);
    }
    &__wrap {
      @apply mt-12 mx-auto;
      max-width: rem(290);
    }
  }
}
