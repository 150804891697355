.hero {
  // &__img {
  //   height: 768px;
  //   margin: 0 calc(50% - 50vw);
  //   @apply relative;
  // }
  @apply bg-center bg-no-repeat bg-cover flex items-center;
  height: rem(768);
  background-image: url(../img/top/sp_hero.jpg);
  @screen sm {
    background-image: url(../img/top/hero.jpg);
  }

  &__text-wrapper {
    // @include position;
    // @apply w-full flex flex-col text-white;
    @apply w-full text-white;
  }

  &__title {
    margin-top: rem(70);
    font-size: rem(50);
    @apply font-extrabold font-yumincho;

    @screen sm {
      margin-top: rem(140);
    }
  }

  &__text {
    @apply font-bold font-yugothic text-base leading-loose;
    margin-top: rem(60);
    @screen sm {
      @apply text-lg;
    }
  }
}
