.footer-inquiry {
  @apply pt-24;
  padding-bottom: rem(131);
  font-size: rem(32);
  @screen sm {
    padding-top: rem(62);
    padding-bottom: rem(85);
  }
  &__caption {
    font-size: rem(25);
  }
  &__icon {
    @apply mt-5 mx-auto;
    width: rem(43);
    height: rem(50);
    fill: #15909d;
  }
  &__flex-box {
    @screen sm {
      width: 45.6%;
    }
  }
  &__link {
    @apply bg-white flex justify-center items-center;
    height: rem(174);
    border-radius: 10px;
  }
  &__tel{
    font-size: rem(50);
  }
}
