.top-business {
  @apply relative bg-no-repeat;
  background-image: url(../img/top/bg_business2.png), url(../img/top/bg_business1.jpg);
  background-position: top 230px right -65px, center top;
  background-size: auto 534px, cover;
  clip-path: polygon(0% 0%, 100% 0%, 100% calc(100% - 90px), 50% 100%, 0 calc(100% - 90px));
  @screen sm {
    background-position: top 146px right 0, left 0 top 0;
    background-size: auto 636px, cover;
    // clip-path: polygon(0% 0%, 100% 0%, 100% calc(100% - 96px), 50% 100%, 0 calc(100% - 96px));
    clip-path: polygon(0% 0%, 100% 0%, 100% 577px, 50% 100%, 0 577px);
  }
  @screen lg {
    background-position: top 146px right calc((100vw - 1040px) / 2), left 0 top -145px;
  }
  @screen xs {
    min-height: rem(742);
  }
  > * {
    @apply relative;
    z-index: 1;
  }
  &::after {
    @apply absolute w-full;
    top: 0;
    left: 50%;
    height: 100%;
    content: '';
    background-color: rgba(#08184e, .7);
    transform: translateX(-50%);
  }
  .icon_check {
    > svg {
      fill: #fff;
    }
  }
}
