.philosophy {
  &__lead-line1 {
    @apply text-primary-green;
    font-size: rem(38);
    @screen sm {
      font-size: rem(50);
    }
    &::before,
    &::after {
      @apply bg-primary-green inline-block;
      width: 3px;
      height: rem(27);
      content: '';

    }
    &::before {
      transform: translate(-17px, 0) rotate(-25deg);
      @screen sm {
        transform: translate(-22px, -4px) rotate(-25deg);
      }
    }
    &::after {
      transform: translate(17px, 0) rotate(25deg);
      @screen sm {
        transform: translate(22px, -4px) rotate(25deg);
      }
    }

  }
  &__lead-line2 {
    margin-top: rem(30);
    font-size: rem(22);
    @screen sm {
      font-size: rem(30);
    }
  }
  &__point {
    width: rem(205);
    @screen sm {
      width: rem(268);
    }
  }
  &__point-list {
    @apply text-left mt-3;
    font-size: rem(28);
    @screen sm {
      font-size: rem(37);
    }
    // &::before {
    //   @apply bg-primary-green inline-block;
    //   width: rem(40);
    //   height: rem(43);
    //   content: '';
    // }
  }
  &__thumbs-up {
    @apply inline-block align-bottom mr-4;
    width: rem(40);
    height: rem(43);
    fill: #15909d;
    @screen sm {
      @apply align-text-bottom;
      width: rem(52);
      height: rem(56);
    }
  }
  &__bottom {
    font-size: rem(22);
    @screen sm {
      font-size: rem(30);
    }
  }
}
