.img {
  height: 100%;
}

body {
  @apply font-arial text-primary-blue;
  @at-root _:-ms-fullscreen, :root &,
  &.ie.windows {
    font-family: 'Meiryo', sans-serif;
  }
}

.wrapper {
  max-width: calc(#{theme('maxWidth.screen-md')} + #{theme('spacing.10')});
  @apply px-4 w-full mx-auto;
}
