.top-button {
  @apply absolute rounded-full bg-primary-blue flex items-center justify-center;
  top: - rem(89);
  right: rem(17);
  width: rem(70);
  height: rem(70);
  @screen sm {
    top: - rem(35);
    right: rem(27);
  }
  > svg {
    width: rem(30);
    height: rem(30);
    fill: #fff;
  }
}
