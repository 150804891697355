.lower-hero {
  @apply bg-center bg-no-repeat bg-cover flex items-center justify-center;
  height: rem(259);
  background-image: url(../img/common/sp_hero.jpg);
  @screen sm {
    height: rem(200);
    background-image: url(../img/common/hero.jpg);
  }
  &__title{
    @apply text-white font-bold text-center;
    &-ja {
      @apply text-2xl block;
    }
    &-en {
      @apply text-sm block mt-5;
    }
  }
}
