@use '@foundations/fontface';
@use '@foundations/css_variables';
@use '@foundations/reset';
@use '@foundations/animations';
@use '@foundations/@foundations' as *;
@use '@foundations/base';

@use 'libs/modal';
@use 'frame/breadcrumbs';

@import 'libs/modal.scss';
@import 'libs/swiper.scss';
@import 'frame/_breadcrumbs.scss';
@import 'frame/_frame.scss';
@import 'frame/_global_navigation.scss';
@import 'layouts/_breadcrumbs.scss';
@import 'layouts/_footer-inquiry.scss';
@import 'layouts/_hero.scss';
@import 'layouts/_layout.scss';
@import 'layouts/_lower-hero.scss';
@import 'layouts/inquiry/_inquiry.scss';
@import 'layouts/philosophy/_philosophy.scss';
@import 'layouts/top/_top-business.scss';
@import 'objects/_button.scss';
@import 'objects/_icon_check.scss';
@import 'objects/_object.scss';
@import 'objects/_top-button.scss';
@import 'objects/button/_button_navigation.scss';
@import 'pages/test.scss';

.hover\:primary {
  @include hover(primary);
}

//.sample {
//  @apply py-2 px-4 flex;
//  メディア切り替え(min-width) xsの場合
//  @screen xs {
//    @apply hidden;
//  }
//  メディア切り替え(min-width) smの場合
//  @screen sm {
//    @apply block;
//  }
//  ホバーの体裁
//  @include hover {
//    @apply py-8 px-2;
//  }
//}
