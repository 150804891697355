.button_navigation {
  position: relative;
  width: rem(45);
  height: rem(45);
  margin-left: auto;
  transition: background-color .5s;
  > span {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    pointer-events: none;
  }
  &--line {
    @apply bg-white;
    position: absolute;
    top: 50%;
    left: 50%;
    display: block;
    width: 100%;
    // width: percentage(.9);
    height: 3px;
    border-radius: 1.5px;
    transition: background-color .5s;
    transform: translate(-50%,-50%);
  }
  &::before,&::after {
    content: '';
    transition: margin-top .25s .25s, transform .25s;
    @extend .button_navigation--line;
  }
  &::before {
    margin-top: rem(12,-1);
  }
  &::after {
    margin-top: rem(12);
  }
}


.button_navigation[aria-expanded='true'] {
  pointer-events: none;
  > span {
    pointer-events: auto;
  }
  .button_navigation {
    &--line {
      background: transparent;
    }
  }
  &::before, &::after {
    margin-top: 0;
    transition: margin-top .25s, transform .25s .25s;
  }
  &::before {
    transform: translate(-50%, -50%) rotate(45deg);
  }

  &::after {
    transform: translate(-50%, -50%) rotate(-45deg);
  }
}
