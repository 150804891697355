@use "../../variables.json" as variables;

//rootに標示させる用
:root {
  // breakpoints
  @each $key,$value in variables.$breakpoints {
    --breakpoint-#{$key}: #{map-get(variables.$breakpoints, $key)};
  }

  // 色
  @each $key,$value in variables.$colors {
    @each $deepKey,$deepValue in $value {
      --color-#{"" + $key}-#{"" + $deepKey}: #{$deepValue};
    }
  }

  // fonts
  @each $key,$value in variables.$fontFamily {
    --font-#{$key}: #{$value};
  }

  // z-index
  @each $key,$value in variables.$zIndex {
    --z-#{$key}: #{$value};
  }
}
