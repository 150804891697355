.global_navigation {
  @screen md {
    @apply flex items-center h-full justify-end;
  }
  &--item {
    @apply border-0 border-b border-solid border-white;
    @screen md {
      @apply border-0;
    }
  }
  &--link {
    @apply text-white text-center font-bold p-8;
    @screen md {
      @apply p-0 ml-12;
    }
    > span {
      @apply block;
    }
    .ja {
      @apply text-xl;
      @screen md {
        @apply text-base;
      }
    }
    .en {
      @apply text-base mt-1;
      @screen md {
        @apply text-xs mt-2;
      }
    }
  }
}
